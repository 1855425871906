'use client';

import Image from 'next/image';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import classes from './footer.module.scss';
import VOHLogo from '@/assets/footer_logo.png';
import Link from 'next/link';
import { Button } from '@mui/material';
import { DateTime } from 'luxon';
import DOMPurify from 'isomorphic-dompurify';
import { useState } from 'react';
import { enCodeEventLink, validateEmail } from '@/utils';
import { postAPICall } from '@/utils/network';
import SubScribeDialog from './SubScribeDialog';

export default function Footer({ appBarData, eventsData }: any) {
    const [email, setMail] = useState<string>('');
    const [error, setError] = useState<any>('');
    const [showSnackBar, setShowSnackBar] = useState({
        open: false,
        type: 'success',
        message: 'Thank you, Your response was submitted successfully!',
    });

    const handleChange = (e: any) => {
        setMail(e.target.value);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!email) return setError('Email is required');
        if (!validateEmail(email)) return setError('Invalid Email');
        setError(false);
        const [data, error]: [any, any] = await postAPICall(
            '/newsletter/subscribe',
            { emailId: email },
            'application/json',
        );
        if (data)
            return setShowSnackBar({
                open: true,
                type: 'success',
                message: 'Thank you, Your response was submitted successfully!',
            });
        setShowSnackBar({
            open: true,
            type: 'error',
            message: error?.error || 'Something went wrong...',
        });
    };

    return (
        <footer className="bg-cyan-600 text-white w-full mt-5">
            <div className={`max-w-[95%] mx-auto mt-10 ${classes.footerContainer}`}>
                <div className={classes.left}>
                    <div className="flex gap-2 w-full justify-center lg:justify-start">
                        <Image src={VOHLogo} width={80} height={50} alt="VOH Logo" />
                        <Link href="https://www.facebook.com/vohglobal" target="_blank" aria-label="Social Media Link">
                            <FacebookIcon fontSize="small" />
                        </Link>
                        <Link href="https://twitter.com/vohglobal" target="_blank" aria-label="Social Media Link">
                            <TwitterIcon fontSize="small" />
                        </Link>
                        <Link
                            target="_blank"
                            href="https://instagram.com/vohglobal?igshid=NDk5N2NlZjQ="
                            aria-label="Social Media Link"
                        >
                            <InstagramIcon fontSize="small" />
                        </Link>
                        <Link
                            target="_blank"
                            href="https://www.linkedin.com/company/vohglobal/?viewAsMember=true"
                            aria-label="Social Media Link"
                        >
                            <LinkedInIcon fontSize="small" />
                        </Link>
                        <Link
                            target="_blank"
                            href="https://www.youtube.com/@VoiceofHealthcare"
                            aria-label="Social Media Link"
                        >
                            <YouTubeIcon fontSize="small" />
                        </Link>
                    </div>
                    <p className="mt-5 text-justify lg:text-left">
                        Voice of Healthcare acts as a industry connect platform that sources ideas, synergizes
                        stakeholders and solves bottlenecks to advance healthcare relentlessly.
                    </p>
                </div>
                <div className={classes.right}>
                    {Array.isArray(appBarData) &&
                        appBarData.slice(0, 3).map((item, idx) => {
                            return (
                                <div key={idx} className="flex flex-col gap-2">
                                    <h1 className="text-xl font-extrabold">{item.name}</h1>
                                    {Array.isArray(item.children?.primary) &&
                                        item.children?.primary?.map((child: any, idx: number) => {
                                            return (
                                                <Link href={child.href} className="hover:underline" key={idx}>
                                                    <h1>{child.name}</h1>
                                                </Link>
                                            );
                                        })}
                                </div>
                            );
                        })}
                    <div className="flex flex-col gap-2">
                        <h1 className="text-xl font-extrabold">Collaborations</h1>
                        <Link href={'/updates'} className="hover:underline">
                            <h1>Updates</h1>
                        </Link>
                        <Link href={'/events'} className="hover:underline">
                            <h1>Events</h1>
                        </Link>
                        <Link href={'/allbroadcast'} className="hover:underline">
                            <h1>VOH TV</h1>
                        </Link>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h1 className="text-xl font-extrabold">More</h1>
                        <Link href={'/about'} className="hover:underline">
                            <h1>About Us</h1>
                        </Link>
                        <Link href={'/team'} className="hover:underline">
                            <h1>Meet the Team</h1>
                        </Link>
                        <Link
                            href={'https://drive.google.com/file/d/1zU-K4QirpnCe4zIQDOFxh0LyLNDoiS8v/view'}
                            target="_blank"
                            className="hover:underline"
                        >
                            <h1>Privacy Policy</h1>
                        </Link>
                    </div>
                </div>
            </div>
            <div
                className={`max-w-[95%] mx-auto mt-10 flex flex-col lg:flex-row gap-x-10 gap-y-5 justify-between items-center`}
            >
                <div className="flex w-full max-w-[400px] flex-col">
                    <label htmlFor="input" className="font-semibold">
                        Subscribe to our newsletter
                    </label>
                    <div className="flex relative">
                        <input
                            id="input"
                            placeholder="Your Email address"
                            className="bg-transparent outline-none placeholder:text-white p-2 border rounded-md w-full"
                            onChange={handleChange}
                            value={email}
                        />
                        <Button
                            variant="contained"
                            color="error"
                            className="absolute right-10"
                            size="large"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        {error && <p className="absolute bottom-[-25px] left-0 right-0">{error}</p>}
                    </div>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 md:order-2">
                    {Array.isArray(eventsData) &&
                        eventsData.slice(0, 4).map((event, idx) => {
                            return (
                                <Link
                                    href={`${enCodeEventLink(event?.eventName, event?.eventId)}`}
                                    target="_blank"
                                    key={idx}
                                >
                                    <div className="flex-col" key={idx}>
                                        <Image
                                            src={`${event.eventBannerImage}`}
                                            width={300}
                                            height={200}
                                            className="w-full shadow-lg"
                                            alt=""
                                        />
                                        <p
                                            className="text-white overflow-clip text-sm line-clamp-1 editor"
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(event.eventName),
                                            }}
                                        ></p>
                                    </div>
                                </Link>
                            );
                        })}
                </div>
            </div>
            <p className="flex font-proxima justify-center my-10 ">
                &copy; All Rights Reserved by VOH {DateTime.now().year}
            </p>
            <SubScribeDialog showSnackBar={showSnackBar} setShowSnackBar={setShowSnackBar} />
        </footer>
    );
}
